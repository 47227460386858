.sti_holder {
    margin: 0 0 67px;
}

.sti_subtitle {
    text-align: center;
    padding: 0 20%;
}

.sti_title_subtitle_holder {
    padding: 100px 0 91px;
}