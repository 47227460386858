@import './variables';

footer {

    p {
        color: #000;

        a:hover {
            color: $second_main_color;
        }
    }

    .footer_top {
        background-image: url('./img/footer-background-image.jpg');
        background-position: 0;
        padding: 120px 0;

        .footer_top_col {
            margin-bottom: 50px;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                margin-bottom: 0;
            }
        }
    }

    .footer_top_text {
        > div {
            margin-top: 25px;
        }

        > ul {
            margin-top: 31px;
        }
    }

    .footer_bottom {
        height: 53px;
        background-color: $first_main_color;
        text-align: center;
        font-size: 14px;

        p {
            line-height: 53px;
        }
    }
}