button, input[type="submit"], .button {
    display: inline-block;
    color: #000;
    border: 0;
    font-size: 14px;
    line-height: 36px;
    height: 36px;
    font-style: normal;
    font-weight: 700;
    background-color: #ccec81;
    border-radius: 19px;
    -moz-border-radius: 19px;
    -webkit-border-radius: 19px;
    letter-spacing: 0;
    text-transform: lowercase;
    padding-left: 47px;
    padding-right: 47px;
    min-width: 0;
}

input[disabled] {
    cursor: not-allowed;
}