.slider_home_page {
    height: calc(100vh - 90px);

    @media (min-width: 992px) {
        height: calc(100vh - 120px);
    }

    .slide {
        position: relative;
    }
}

.slide_one {
    background-color: #e9f8ff;
}

.slide_two {
    background-color: #e9f8ff;
}

.slide_three {
    background-color: #f3f3f3;
}

.slide_element {
    display: block;
    position: absolute;
}

.slide_holder {
    height: 100%;
    position: relative;
    z-index: 2;
}

.slide_main_image_holder {
    position: relative;
}

.slide_main_image {
    display: block;
    width: 55%;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    @media (min-width: 992px){
        width: 70%;
    }
}

.slide_image_cookie {
    height: auto;
    left: 15%;
    top: 65%;
    width: 24.5%;
    z-index: 1;
}

.slide_image_dots {
    width: 30%;
    top: 3%;
    left: 0%;
    z-index:1;
}

.slide_image_green {
    width: 23.06%;
    height: auto;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.slide_image_xxx {
    width: 5.9%;
    right: 3.47%;
    bottom: 6%;
    z-index: 2;
}

.slide_image_square {
    top: -24%;
    width: 20%;
    left: -4%;
    z-index: 1;
}

.slide_image_circle {
    width: 7.36%;
    right: -1.736%;
    top: 15%;
}

.slide_image_alpha {
    width: 2.569%;
    right: 0.347%;
    top: 22%;
}

.slide_text_title {
    line-height: 1.1em;
}

.slide_text_subtitle {
    color: #333;
    font-size: 19px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 30px;
}

.slide_button_link {
    margin-top: 40px;
}

.slide_text_holder {
    position: relative;
    z-index: 2;
    padding: 0 5% 0;
    text-align: center;
    transform: translateY(-5%);

    h1 {
        font-size: 45px;
    }

    @media (min-width: 540px){
        transform: translateY(-15%);

        h1 {
            font-size: 55px;
        }
    }

    @media (min-width: 992px){
        padding: 0 15% 0 0;
        text-align: left;
        transform: none;
    }
}