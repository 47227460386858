@import './variables';

.map_address_holder {
    padding: 130px 0;    
}

.address_holder {
    padding: 0 0 0 10%;
    margin-top: -10px;

    & > div {
        margin-top: 30px;

        &.address {
            margin-top: 10px;
        }
    }
}


.google_map_holder {
    position: relative;
    padding-top: 70%;
    width: 90%;
    
    > div {
        position: absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
    }
}

.contact_info > * {
    display: inline-block;
    vertical-align: middle;
}

.contact_info a:hover {
    color: $second_main_color;
}

.contact_icons {
    margin-right: 10px;
    
    .mobile & {
        margin-right: 16px;
    }
}

.social_icons_holder {

    .address_holder & {
        margin-top: 40px;
    }

    a {
        display: inline-block;
        font-size: 28px;
        margin-right: 20px;

        &:hover {
            color: $second_main_color;
        }
    }
}

.contact_form_wrapper {
    background-image: url('../scss/img/contact-form.jpg');
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 100px 0 130px;
    background-color: #CAECF5;
}

.contact_form_title_holder {
    padding: 62px 0 30px;
    background-image: url('../scss/img/cf-title-background.png');
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
}

.contact_form {
    input[type="text"], textarea {
        background-color: #fff5f5;
        border-color: transparent;
        border-width: 0;
        border-style: solid;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
        border-bottom-left-radius: 26px;
        color: #777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-left: 22px;
        margin-bottom: 20px;

        &:focus {
            outline: none;
        }
    }

    textarea {
        height: 230px;
    }

    input[type="submit"] {
        display: block;
        margin: 41px auto 0;
    }
}