.title_with_background {
    background-repeat: no-repeat;
    padding: 52px 0 22px;

    &.center {
        text-align: center;
        background-position: center center;
    }

    &.right {
        text-align: right;
        background-position: center right;
    }
}