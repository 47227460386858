.title_area {
    height: 223px;
    background-position: center 0;
    background-repeat: no-repeat;

    h1 {
        font-size: 47px;
        line-height: 223px;
        text-align: center;
    }
}