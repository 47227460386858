.gallery_slider_wrapper {
    margin: 0 0 100px;
    overflow: hidden;
}

.gallery_slider {
    margin: 0 -5px 0;

    .gallery_slide_inner {
        overflow: hidden;
    }

    .gallery_slide_inner img{
        transform: scale(1);
        transition: transform 0.3s ease-in-out;
    }

    .gallery_slide_inner:hover img{
        transform: scale(1.03);
    }

    .gallery_slide {
        padding: 0 5px;
        box-sizing: border-box;

        img {
            display: block;
            width:100%;
        }
    }
}