.process_holder {
    text-align: center;

    img {
        width: 274px;
        display: block;
        margin: 0 auto;
        border-radius: 50%;
    }
}

.process_item_holder {
    margin-bottom: 70px;
}

.process_item_text_holder {
    margin: 25px 0 0;

    & > p {
        margin: 5px 16% 0;
    }
    
}