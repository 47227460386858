@import './variables';

header {
    width: 100%;
}

.header_top {
    display: none;
    height: 30px;
    line-height: 30px;
    background-color: $first_main_color;

    @media (min-width: 992px){
        display: block;
    }

    .header_top_inner {
        padding: 0;
    }

    .header_top_elements_holder > p {
        line-height: 30px;
    }

    .left {
        float:left;

        > p {
            float: left;

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .right {
        float:right;

        > p {
            float: right;

            &:first-child {
                padding-right: 0;
            }
        }
    }

    p {
        color: #333;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        padding: 0 15px;

        a:hover {
            color: $second_main_color;
        }
    }
}

.header_bottom {
    height: 90px;
    position: relative;
    margin: 0 auto;
    text-align: center;

    &.container { //to override default container padding
        padding: 0;
    }

    .logo {
        display: inline-block;
        max-height: 100%;
        height: 70px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        @media (min-width: 992px){
            display: block;
            float: left;
        }

        a {
            display: block;
            height: 100%;
        }

        img {
            display: block;
            position: relative;
            height: 100%;
            max-height: 100%;
        }
    }
}

.main_nav_menu {
    display: none;
    top: 0;
    float:right;

    @media (min-width: 992px){
        display: block;
    }

    > ul > li { 
        display: inline-block;
        margin: 0;

        &:not(.wide_dropdown) {
            position: relative;
        }

        &:last-child > a {
            padding-right: 0;
        }

        > a {
            color: #010101;
            padding:0 20px;
            line-height: 90px;
            font-weight: 700;
            font-size: 17px;
        }

        > a.active, a:hover {
            color: $first_main_color;
        }

        &:hover > .dropdown_menu {

            &.narrow, &.wide {
                display: block;
            }

        }
    }

}

.main_nav_menu > ul > li > .dropdown_menu {
    position: absolute;
    display: none;
    z-index: 3;
    background-color: #fff;
}

.main_nav_menu > ul > li > .dropdown_menu.narrow {
    top: 100%;
    left: 0;

    li {
        position: relative;
        width: 225px;
        margin: 0;

        > a {
            display: block;
            padding: 9px 20px;
        }

        > ul {
            position: absolute;
            top: 0;
            left: 100%;
            background-color: #fff;
            display:none;
        }

        &:hover > ul {
            display: block;
        }
    }

    ul {
        padding: 13px 0;
    }
}

.main_nav_menu > ul > li > .dropdown_menu.wide {
    position:absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    margin: 0;


    ul li {
        margin: 0;

        > a {
            font-size: 14px;
            line-height: 1.5em;
            color:#777;

            &:hover {
                color: $first_main_color;
            }
        }
    }

    h5 > a:hover {
        color: $second_main_color;
    }


    > ul {
        overflow: hidden;
    }

    &.container {
        padding: 27px 0 40px;
    }

    .dropdown_menu_column {
        width: 25%;
        float: left;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            height: 10000px;
            width: 1px;
            background-color: rgba(0,0,0,.1);
            top: 0;
            right: 0;
        }

        &:last-child:after {
            display:none;
        }

        > ul {
            padding: 20px 0 0;
        }

        a {
            display: block;
            padding: 6px 35px;
        }
    }
}