.our_services_holder {
    padding: 100px 0 70px;
}

.our_services_subtitle {
    text-align: center;
    padding: 0 5%;

    @media (min-width: 992px){
        padding: 0 14%;
    }

    @media (min-width: 1200px){
        padding: 0 17%;
    }
    
}

.our_services {
    margin: 100px 0 0;
}

.our_services_item {
    margin-bottom: 70px;
}

.image_with_text {
    text-align: center;

    .iwt_title {
        margin: 30px 0 0;
    }

    .iwt_text {
        margin: 10px 0 0;
    }

    img {
        display: block;
        margin: 0 auto;
        width: 104px;
    }
}