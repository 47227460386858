@import './variables';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:wght@0,700');

html {
    overflow: auto;
}

body {
    font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
    line-height: 1.65em;
}

h1 {
    font-size:55px;
}

h2 {
    font-size: 45px;
}

h3 {
    font-size: 38px;
}

h4 {
    font-size: 25px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

a {
    color: #303030;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

ul li {
    margin: 19px 0;
    font-size: 15px;
}

p {
    color: #777;
    font-size: 15px;
    line-height: 26px;
    font-style: normal;
    font-weight: 400;

    a {
        color: #303030;

        &:hover {
            color: $first_main_color;
        }
    }
}

img {
    max-width: 100%;
}

.subtitle {
    color: #555;
    font-weight: 400;
}

.font_awesome {
    display: inline-block;
}

@media (min-width: 1366px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1300px;
    }
}

@import './button';