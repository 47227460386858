.seek_for_help {
    background-image: url('./img/seek-for-help.jpg');
    background-size: 110% auto;
    background-repeat: no-repeat;
    padding: 100px 0 93px;
    background-color: #fff5f5;

    p {
        margin-bottom: 20px;
    }

    .button {
        margin-top: 40px;
    }
}

.sfh_image_holder {
    padding: 0;

    & > img {
        display: block;
        width: 517px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .sfh_image_holder {
        padding: 2% 19% 0% 0%;

        & > img {
            margin: 0;
        }
    }
}